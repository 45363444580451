import { baseIssuerUrl } from './../variables';
import { UserManagerService } from './user-manager.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

@Injectable()

export class SignetHttpInterceptor implements HttpInterceptor {
  constructor(private userManager: UserManagerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        // Add authorization token
        Authorization: 'Bearer ' + this.userManager.getAccessToken()
      }
    });

    if (req.url.startsWith(baseIssuerUrl)) {
      req = req.clone({
        setHeaders: {
          // Sets the access token for identity server requests
          Authorization: 'Bearer ' + this.userManager.getAccessToken()
        }
      });
    }

    return next.handle(req);
  }
}
