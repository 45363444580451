import { AdminService } from './../admin.service';
import { UserManagerService } from './../user-manager.service';
import { PolicyService } from './../policy.service';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ApplicationPolicy } from '../models/policy-service.models';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { tap, map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'signet-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss']
})

export class PoliciesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('policyName', { read: ElementRef, static: false }) policyName: ElementRef;

  policies$: Observable<ApplicationPolicy[]>;
  displayedColumns = ['name', 'canEdit', 'manage'];
  ngUnsubscribe: Subject<void> = new Subject();
  dataSource: MatTableDataSource<ApplicationPolicy> = new MatTableDataSource();
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  addNewPolicyStream: BehaviorSubject<boolean> = new BehaviorSubject(false);
  newPolicyFormControl = new FormControl('', { updateOn: 'blur', validators: Validators.required});
  isUserAdmin: Observable<boolean>;
  constructor(
    private policyService: PolicyService,
    private router: Router,
    private userManagerService: UserManagerService,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.policies$ = this.policyService.getApplicationPolicies().pipe(
      tap(p => this.isLoading$.next(true)),
      tap((appPolicies: ApplicationPolicy[]) => {
        this.dataSource.data = appPolicies;
      }),
      tap(p => this.isLoading$.next(false)),
    );

    this.isUserAdmin = this.userManagerService.userRole.pipe(
      map((role: string) => role.toLocaleLowerCase() === 'admin')
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onAddNewPolicyClick() {
    this.addNewPolicyStream.next(true);
    // setTimeout needed to avoid undefined variable while change detection creates this element on the page
    setTimeout(() => this.policyName.nativeElement.focus());
  }

  onCloseFormClick() {
    this.addNewPolicyStream.next(false);
    this.newPolicyFormControl.setValue('');
    this.newPolicyFormControl.markAsUntouched();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSubmitClick() {
    const appPolicy = this.policyService.generateApplicationPolicy();
    appPolicy.name = this.newPolicyFormControl.value;
    // On successful insert, navigate to the newly created policy route to edit permissions
    this.policyService.insertApplicationPolicy(appPolicy).pipe(
      tap(insertedPolicy => {
        // Insert new policy manager item on add of new policy
        this.adminService.postPolicy({appId: insertedPolicy.id, name: insertedPolicy.name, editors: []}).subscribe();
      })
    ).subscribe(
        success => this.router.navigate(['policy', success.id])
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
