import { Observable } from 'rxjs';
import { PolicyService } from './../policy.service';
import { Component, OnInit } from '@angular/core';
import { ApplicationPolicy } from '../models/policy-service.models';

@Component({
  selector: 'signet-policy-detail-back-btn',
  templateUrl: './policy-detail-back-btn.component.html',
  styleUrls: ['./policy-detail-back-btn.component.scss']
})
export class PolicyDetailBackBtnComponent implements OnInit {
  appPolicy: Observable<ApplicationPolicy>;
  constructor(
    private policyService: PolicyService
  ) { }

  ngOnInit() {
    this.appPolicy = this.policyService.applicationPolicy;
  }

}
