import { DeleteConfirmDialogComponent } from './../delete-confirm-dialog/delete-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from './../permission.service';
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { PolicyService } from './../policy.service';
import { Component, OnInit, OnDestroy, EventEmitter, HostListener, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApplicationPolicy, Permission } from '../models/policy-service.models';
import { SelectListItem } from '../models/form-field.models';
import { mapStringToSelectListItem } from '../shared/utils/select-list.utils';
import { tap, map, startWith, takeUntil, switchMap, withLatestFrom, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'signet-permissions-form',
  templateUrl: './permissions-form.component.html',
  styleUrls: ['./permissions-form.component.scss']
})
export class PermissionsFormComponent implements OnInit, OnDestroy {
  @Output() save: EventEmitter<string> = new EventEmitter();

  permission: Observable<Permission>;
  appPolicy: Observable<ApplicationPolicy>;
  permissionForm: FormGroup = new FormGroup({
    permissionName: new FormControl('', Validators.required),
    applicationRoles: new FormControl([], { updateOn: 'blur' })
  });
  selectListOptions: SelectListItem[];
  permissionSavedStream: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isReadOnly: Observable<boolean>;
  isDeleting: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canDelete: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private saveStream: Subject<void> = new Subject();
  private ngUnsubscribe: Subject<void> = new Subject();
  private permissionStream: BehaviorSubject<Permission> = new BehaviorSubject({} as Permission);

  constructor(
    private policyService: PolicyService,
    private permissionService: PermissionService,
    private router: Router,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    combineLatest(
      this.permissionForm.controls.permissionName.valueChanges.pipe(startWith('')),
      this.permissionForm.controls.applicationRoles.valueChanges.pipe(startWith([]))
    ).pipe(
      tap(p => this.permissionSavedStream.next(false)),
      map((aggregate: [string, any[]]) => {
        const roles = aggregate[1].map(item => {
          if (!!item.viewValue) {
            return item.viewValue;
          } else {
            return item;
          }
        });
        return ({ name: aggregate[0], roles });
      }),
      tap((permission: Permission) => this.permissionStream.next(permission)),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();

    this.isReadOnly = this.policyService.isReadOnly.pipe(
      tap(isReadOnly => isReadOnly ? this.permissionForm.disable() : this.permissionForm.enable())
    );

    this.permission = this.permissionService.currentPermission;

    // We don't want to subscribe in the template since this value may be null for a new permission
    this.permission.subscribe();

    this.appPolicy = this.policyService.applicationPolicy.pipe(
      withLatestFrom(this.permission),
      map(aggregate => ({ policy: aggregate[0], permission: aggregate[1] })),
      tap(appPolicy => {
        if (!!appPolicy.policy) {
          const roles = appPolicy.policy.policy.roles.map(role => role.name);

          this.selectListOptions = this.mapPolicyRolesToSelectListItem(roles, appPolicy.policy);

          // If permission is set, set the form values, otherwise they are instantiated with the default values
          if (!!appPolicy.permission) {
            this.canDelete.next(true);
            this.permissionForm.controls.permissionName.setValue(appPolicy.permission.name);
            this.permissionForm.controls.applicationRoles.setValue(
              this.mapPolicyRolesToSelectListItem(appPolicy.permission.roles, appPolicy.policy)
            );
            // We don't want to count these values being set as modifying the form, so mark as pristine
            this.permissionForm.markAsPristine();
          } else {
            this.canDelete.next(false);
          }
        }
      }),
      map(aggregate => aggregate.policy)
    );

    this.saveStream.pipe(
      withLatestFrom(this.permissionStream),
      map(aggregate => aggregate[1]),
      withLatestFrom(this.appPolicy),
      map(aggregate => ({
        permission: aggregate[0],
        appPolicy: aggregate[1]
      })),
      map(appPolicyWithPermission => {
        const appPolicy = appPolicyWithPermission.appPolicy;

        const permissionIndex = appPolicy.policy.permissions
          .findIndex(permission => permission.name === appPolicyWithPermission.permission.name);
        if (permissionIndex > -1) {
          if (!this.isDeleting.value) {
            appPolicy.policy.permissions[permissionIndex] = appPolicyWithPermission.permission;
          } else {
            appPolicy.policy.permissions.splice(permissionIndex, 1);
          }
        } else {
          appPolicy.policy.permissions.push(appPolicyWithPermission.permission);
        }

        return appPolicy;
      }),
      switchMap(policy => {
        if (this.permissionForm.valid) {
          if (!!policy.id) {
            return this.policyService.updateApplicationPolicy(policy.id, policy);
          } else {
            return this.policyService.insertApplicationPolicy(policy);
          }
        }
      }),
      tap(p => this.save.emit(this.permissionForm.controls.permissionName.value)),
      tap(p => this.snackbar.open(`Successfully saved permission: ${this.permissionStream.value.name}`, 'Dismiss', { duration: 1500 })),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(success => {
      this.permissionSavedStream.next(true);
      // We don't receive an object back on update, so use the policy we are already tracking
      this.appPolicy.pipe(
        take(1)
      ).subscribe(policy => {
        this.router.navigate(['/', 'policy', policy.id]);
        this.policyService.applicationPolicyId.next(policy.id);

        this.policyService.refreshStream.next();
      });
    });
  }

  mapPolicyRolesToSelectListItem(arr: string[], appPolicy: ApplicationPolicy): SelectListItem[] {
    const opts = mapStringToSelectListItem(arr);
    return opts.map(item => {
      this.permission.pipe(
        map(permission =>
          appPolicy.policy.permissions.find(perm => perm.name === permission.name)),
        map(permission => permission.roles.find(role => role === item.value)),
        tap(role => {
          if (!!role) {
            item.isSelected = true;
          }
        })
      );

      return item;
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event: BeforeUnloadEvent) {
    $event.stopPropagation();
    return this.handleUnload($event);
  }

  @HostListener('window:unload', ['$event'])
  onUnload($event: BeforeUnloadEvent) {
    $event.stopPropagation();
    this.handleUnload($event);
  }

  handleUnload($event: BeforeUnloadEvent): BeforeUnloadEvent {
    if (!this.permissionForm.pristine && !this.permissionSavedStream.value) {
      if (confirm('Are you sure you wish to leave this page without saving your changes?')) {
        $event.returnValue = 'Your changes will not be saved';
      } else {
        $event.returnValue = 'Continue';
      }
    }

    return $event;
  }

  onSaveClicked() {
    if (this.permissionForm.valid) {
      this.saveStream.next();
    }
  }

  onDeleteClicked() {
    const deleteDialog = this.dialog.open(DeleteConfirmDialogComponent);
    deleteDialog.componentInstance.confirmed.pipe(
      take(1)
    ).subscribe(
      success =>  {
        this.isDeleting.next(true);
        this.saveStream.next();
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
