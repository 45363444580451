import { ApplicationPolicy } from './../models/policy-service.models';
import { PolicyService } from './../policy.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormArray, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AdminService } from './../admin.service';
import { Observable, BehaviorSubject, Subject, forkJoin, of } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminPolicy } from '../models/admin.models';
import { tap, map, switchMap, takeUntil, withLatestFrom, shareReplay, catchError, filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'signet-admin-policy-management',
  templateUrl: './admin-policy-management.component.html',
  styleUrls: ['./admin-policy-management.component.scss']
})
export class AdminPolicyManagementComponent implements OnInit, OnDestroy {
  policy: Observable<AdminPolicy>;
  displayedColumns = ['name', 'edit'];
  ngUnsubscribe: Subject<void> = new Subject();
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  saveStream: Subject<void> = new Subject();
  form: FormGroup = new FormGroup({
    editors: new FormArray([])
  });
  private editors = 'editors';
  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private policyService: PolicyService
  ) { }

  ngOnInit() {
    this.policy = this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('id')),
      tap(p => this.isLoading$.next(true)),
      switchMap(id => forkJoin(
        this.adminService.getPolicyById(id),
        this.policyService.getApplicationPolicy(id).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              return of(undefined);
            }
          }),
          tap(applicationPolicy => {
            if (!applicationPolicy) {
              this.router.navigate(['/']);
              this.snackbar.open('The policy you are trying to manage does not exist!', 'Dismiss', { duration: 1500 });
            }
          })
        )
      )),
      filter(p => !!p[0] && !!p[1]),
      map((aggregate: [AdminPolicy, ApplicationPolicy]) => {
        return ({
          appId: aggregate[1].id,
          name: aggregate[1].name,
          editors: aggregate[0].editors
        });
      }),
      tap(policy => {
        if (policy.editors.length > 0) {
          policy.editors.forEach(editor => {
            this.getNestedFormArray(this.editors).push(new FormControl(editor));
          });
        } else {
          this.getNestedFormArray(this.editors).push(new FormControl());
        }

      }),
      tap(p => this.isLoading$.next(false)),
      // Add replay so we do not run this logic on every subscription
      shareReplay(1)
    );

    this.saveStream.pipe(
      map(stream => {
        // Get the values out of the form array
        const formArray = this.getNestedFormArray(this.editors);
        return formArray.controls.filter(control => !!control.value).map(control => control.value);
      }),
      withLatestFrom(this.policy),
      map((aggregate: [string[], AdminPolicy]) => ({ appId: aggregate[1].appId, name: aggregate[1].name, editors: aggregate[0] })),
      switchMap((policy: AdminPolicy) => this.adminService.putPolicy(policy.appId, policy)),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(success => {
      this.router.navigate(['/']);
      this.snackbar.open('Save successful!', 'Dismiss', { duration: 1500 });
    });
  }

  getNestedFormArray(arrayName) {
    const form = this.form.get(arrayName);
    return form as FormArray;
  }

  onClearButtonClick(control: AbstractControl) {
    control.setValue('');
  }

  onSaveClicked() {
    this.saveStream.next();
  }

  onAddClicked() {
    this.getNestedFormArray('editors').push(new FormControl());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
