import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { PermissionsFormComponent } from './permissions-form/permissions-form.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivatePermissionGuard implements CanDeactivate<PermissionsFormComponent> {
  canDeactivate(
    component: PermissionsFormComponent,
  ): boolean {
    if (component.permissionForm.pristine || component.permissionSavedStream.value) {
      return true;
    } else if (confirm('Are you sure you wish to leave this page without saving your changes?')) {
      return true;
    } else { return false; }
  }
}
