import { User } from './../models/user.models';
import { UserManagerService } from './../user-manager.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'signet-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  user: Observable<User>;

  constructor(
    private userManager: UserManagerService
  ) { }

  ngOnInit() {
    this.user = this.userManager.user;
  }

  loginUser() {
    this.userManager.loginUser();
  }

  logoutUser() {
    this.userManager.logoutUser();
  }

}
