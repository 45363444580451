import { PolicyService } from './policy.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanEditGuard implements CanActivate {
  constructor(
    private policyService: PolicyService,
    private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const id = next.paramMap.get('id');
    return this.policyService.getApplicationPolicy(id).pipe(
      map(p => p.canEdit),
      map(canActivate => {
        if (!canActivate) {
          return this.router.createUrlTree(['/']);
        } else {
          return true;
        }
      })
    );
  }

}
