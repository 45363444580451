import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { RolesFormComponent } from './roles-form/roles-form.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateRoleGuard implements CanDeactivate<RolesFormComponent> {
  canDeactivate(
    component: RolesFormComponent
  ): boolean {
    if (component.rolesForm.pristine) {
      return true;
    } else if (confirm('Are you sure you wish to leave this page without saving your changes?')) {
      return true;
    } else { return false; }
  }

}
