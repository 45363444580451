import { AdminService } from './../admin.service';
import { DeleteConfirmDialogComponent } from './../delete-confirm-dialog/delete-confirm-dialog.component';
import { PolicyService } from './../policy.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, tap, map, take, filter, withLatestFrom } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { ApplicationPolicy } from '../models/policy-service.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UserManagerService } from './../user-manager.service';

@Component({
  selector: 'signet-policy-detail',
  templateUrl: './policy-detail.component.html',
  styleUrls: ['./policy-detail.component.scss']
})
export class PolicyDetailComponent implements OnInit, OnDestroy {
  appPolicy$: Observable<ApplicationPolicy>;
  ngUnsubscribe: Subject<void> = new Subject();
  editPolicyName: Observable<boolean>;
  isLoading$: Observable<boolean>;
  isReadOnly: Observable<boolean>;
  formGroup: FormGroup = new FormGroup(
    {
      nameControl: new FormControl()
    }
  );

  private canEditStream: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isAdminPolicy: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isUserAdmin: boolean = false;
  private saveStream: Subject<void> = new Subject();

  constructor(
    private policyService: PolicyService,
    private router: Router,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private adminService: AdminService,
    private userManagerService: UserManagerService
  ) { }

  ngOnInit() {
    this.appPolicy$ = this.policyService.applicationPolicy
    .pipe(
      tap(policy => {
        if (policy) {
          if (policy.id === this.policyService.adminId) {
            this.isAdminPolicy.next(true);
          } else {
            this.isAdminPolicy.next(false);
          }
  
          this.formGroup.controls.nameControl.setValue(policy.name);
        }
      })
    );

    this.editPolicyName = this.canEditStream.pipe(
      tap(canEdit => canEdit ? this.formGroup.controls.nameControl.enable() : this.formGroup.controls.nameControl.disable())
    );

    this.isLoading$ = this.policyService.isLoading$.asObservable();
    this.isReadOnly = this.policyService.isReadOnly.asObservable().pipe(
      tap(isReadOnly => {
        if (isReadOnly) {
          this.formGroup.disable();
        }
      })
    );
    
    this.userManagerService.userRole.subscribe(val => {
      this.isUserAdmin = (val === "Admin");
    });

    this.saveStream.pipe(
      withLatestFrom(this.appPolicy$),
      map(val => val[1]),
      map(appPolicy => {
        appPolicy.name = this.formGroup.controls.nameControl.value;
        return appPolicy;
      }),
      switchMap(policy => this.policyService.updateApplicationPolicy(policy.id, policy)),
      tap(policy => this.policyService.refreshStream.next()),
    ).subscribe(policy => {
      this.canEditStream.next(false);
      this.snackbar.open(`Policy name updated!`, 'Dismiss', { duration: 1500 });
    });
  }

  deletePolicy(id: string) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent);
    dialogRef.componentInstance.resourceType = 'policy';

    dialogRef.componentInstance.confirmed.pipe(
      take(1),
      switchMap(p => this.policyService.deleteApplicationPolicy(id)),
      switchMap(p => this.adminService.deletePolicy(id))
    ).subscribe(success => {
      this.router.navigate(['/']);
      this.snackbar.open('Policy successfully deleted!', 'Dismiss', { duration: 1500 });
    }, err => this.snackbar.open('An error occured while deleting this policy.', 'Dismiss', { duration: 1500 }));
  }

  onEditNameClick() {
    this.canEditStream.next(true);
  }

  onSaveClick() {
    if (this.formGroup.controls.nameControl.valid) {
      this.saveStream.next();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
