import { ApplicationPolicy } from './models/policy-service.models';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { PolicyApi } from './policy.api';
import { Router, ActivationEnd } from '@angular/router';
import { filter, switchMap, takeUntil, map, tap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PolicyService implements OnDestroy {
  applicationPolicy: BehaviorSubject<ApplicationPolicy> = new BehaviorSubject<ApplicationPolicy>(null);
  refreshStream: Subject<void> = new Subject();
  applicationPolicyId: BehaviorSubject<string> = new BehaviorSubject(null);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isReadOnly: BehaviorSubject<boolean> = new BehaviorSubject(false);
  adminId: string
  ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private api: PolicyApi,
    private router: Router,
  ) {
    // Listen to router events, get desired policy by Id and set the ID
    // This will allow us to access the roles and permissions for the application policy by it's ID
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map((event: ActivationEnd) => event.snapshot.paramMap),
      filter(params => params.has('id')),
      tap(params => this.applicationPolicyId.next(params.get('id'))),
      tap(p => this.isLoading$.next(true)),
      switchMap(params => this.getApplicationPolicy(params.get('id'))),
      tap(policy => this.applicationPolicy.next(policy)),
      tap(policy => this.isReadOnly.next(policy.canEdit === false)),
      tap(p => this.isLoading$.next(false)),
      shareReplay(),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(succ => { },
      // Swallow errors here
      err => { });

    // Requests an updated resource for the current policy
    this.refreshStream.pipe(
      switchMap(p => this.applicationPolicyId),
      switchMap(id => this.getApplicationPolicy(id)),
      tap(policy => this.applicationPolicy.next(policy)),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  getApplicationPolicies(): Observable<ApplicationPolicy[]> {
    return this.api.getApplicationPolicies();
  }

  getApplicationPolicy(id: string): Observable<ApplicationPolicy> {
    return this.api.getApplicationPolicy(id);
  }

  insertApplicationPolicy(policy: ApplicationPolicy): Observable<ApplicationPolicy> {
    return this.api.postApplicationPolicy(policy);
  }

  updateApplicationPolicy(id: string, policy: ApplicationPolicy): Observable<ApplicationPolicy> {
    return this.api.putApplicationPolicy(id, policy);
  }

  deleteApplicationPolicy(id: string): Observable<void> {
    return this.api.deleteApplicationPolicy(id);
  }

  clearApplicationPolicy() {
    this.applicationPolicyId.next(undefined);
    this.applicationPolicy.next(undefined);
  }

  generateApplicationPolicy(): ApplicationPolicy {
    return ({
      id: undefined,
      name: undefined,
      policy: {
        roles: [],
        permissions: []
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
