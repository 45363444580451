import { SelectListItem } from './../models/form-field.models';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { debounceTime, switchMap, startWith, takeUntil, tap } from 'rxjs/operators';
import { of, Observable, Subject } from 'rxjs';
@Component({
  selector: 'signet-autocomplete-multiselect',
  templateUrl: './autocomplete-multiselect.component.html',
  styleUrls: ['./autocomplete-multiselect.component.scss']
})
export class AutocompleteMultiselectComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() control: FormControl;
  @Input() options: SelectListItem[];
  @Input() floatLabel: string;
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() fieldId: string;
  @Input() size: 's' | 'm' | 'l' | 'xl' = 'm';
  @ViewChild('input', { static: false, read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

  viewFormGroup: FormGroup = new FormGroup({});
  options$: Observable<SelectListItem[]>;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor() { }

  ngOnInit() {
    this.viewFormGroup.addControl(this.fieldId, new FormControl([]));
    if (!(this.control.value instanceof Array) || !this.control.value) {
      this.control.setValue([]);
    }

    this.options$ = this.viewFormGroup.controls[this.fieldId].valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        switchMap((searchCriteria: string) => {
          if (!searchCriteria) {
            // Show the selected items only
            return of<SelectListItem[]>(this.displayOnEmpty());
          } else {
            // Filter the items by the search criteria, map the resultant array to an array of select list items
            return of<SelectListItem[]>(
              this.options.filter(item => item.viewValue.toLocaleLowerCase().includes(searchCriteria.toLocaleLowerCase()))
                .map(item => {
                  if (item.viewValue.toLocaleLowerCase().includes(searchCriteria.toLocaleLowerCase())) {
                    return ({
                      value: item.value,
                      viewValue: item.viewValue,
                      isSelected: this.indexOfItem(item.value) >= 0
                    });
                  }
                }));
          }
        }),
      );

    this.formGroup.statusChanges.pipe(
      tap(status => this.formGroup.disabled ? this.viewFormGroup.disable() : this.viewFormGroup.enabled),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  onOptionSelected(e: MatAutocompleteSelectedEvent) {
    if (!e.option && !e.option.value) {
      return;
    }
    setTimeout(() => {
      this.trigger.openPanel();
    });
    const index = this.indexOfItem(e.option.value.value);
    if (index >= 0) {
      e.option.value.isSelected = false;
      this.remove(index);
    } else {
      e.option.value.isSelected = true;
      this.add(e.option.value);
    }
    this.control.markAsDirty();
    this.viewFormGroup.controls[this.fieldId].setValue(undefined);
    this.control.updateValueAndValidity();
  }

  private add(item: SelectListItem): void {
    this.control.value.push(item);
  }

  private remove(index: number): void {
    const controlArr = this.control.value as Array<SelectListItem>;
    controlArr.splice(index, 1);
  }

  displayOnEmpty(): SelectListItem[] {
    return this.options.map(item => {
      if (this.control.value.findIndex((controlVal: SelectListItem) => controlVal.viewValue === item.viewValue) > -1) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      return item;
    }).sort((first, second) => {
      if (first.isSelected && !second.isSelected) {
        return -1;
      } else if (!first.isSelected && second.isSelected) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  indexOfItem(itemValue: any): number {
    return this.control.value.findIndex(arrayItem => arrayItem.value === itemValue);
  }

  autocompleteDisplay(option?: SelectListItem): string | undefined {
    return option ? option.viewValue : undefined;
  }

  clearControl() {
    this.control.setValue([]);
    this.viewFormGroup.controls[this.fieldId].setValue(undefined);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
