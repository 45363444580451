import { SvgIconMetadata } from './models/icon.models';
import { AdminPolicyId } from './../variables';
import { map, tap, takeUntil, filter } from 'rxjs/operators';
import { PolicyService } from './policy.service';
import { UserManagerService } from './user-manager.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'signet-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject();
  private svgIconsMetadata: SvgIconMetadata[] = [
    {
      label: 'signet_logo',
      path: '../assets/icons/svg/signet-icon-top-nav.svg'
    }
  ];

  constructor(
    private userManager: UserManagerService,
    private policyService: PolicyService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) {
    this.userManager.configureAuth();
    this.userManager.initialLogin();
    // Register all SVGs that we will be using
    this.svgIconsMetadata.forEach(svgIconMetadata => {
      this.matIconRegistry.addSvgIcon(svgIconMetadata.label, this.sanitizer.bypassSecurityTrustResourceUrl(svgIconMetadata.path), {});
    });
  }

  ngOnInit() {
    this.policyService.adminId = AdminPolicyId;
    
    combineLatest(
      this.policyService.getApplicationPolicies(),
      this.userManager.user
    ).pipe(
      filter(aggregate => !!aggregate[1]),
      map(aggregate => aggregate[0]),
      map(policies => {
        const index = policies.findIndex(policy => policy.id === AdminPolicyId);

        return policies[index];
      }),
      tap(adminAppPolicy => {
        if (!adminAppPolicy) {
          this.userManager.userRole.next('User');
        } else {
          // Check to see if user is an admin in the policy service
          const adminRoleIndex = adminAppPolicy.policy.roles.findIndex(role => role.name.toLocaleLowerCase() === 'admin');
          const adminRole = adminAppPolicy.policy.roles[adminRoleIndex];
          if (adminRole.subjects.findIndex(sub => sub.toLocaleLowerCase() === this.userManager.user.value.sub.toLocaleLowerCase()) > -1) {
            this.userManager.userRole.next('Admin');
          }
        }
      }),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
