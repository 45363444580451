import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringArrayCapitalize'
})
export class StringArrayCapitalizePipe implements PipeTransform {

  transform(value: string[], ...args: any[]): any {
    if (value.length === 0) {
      return '';
    }

    // Formats an array of string to have elements with capitalzied first letters and spaces between commas
    return value.map((str, index) => {
      str = str[0].toLocaleUpperCase() + str.slice(1);

      if (index !== 0) {
        str = ' ' + str;
      }

      return str;
    });
  }

}
