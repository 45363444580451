import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AdminApi } from './admin.api';
import { Observable } from 'rxjs';
import { AdminPolicy, AdminPolicyDto } from './models/admin.models';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private api: AdminApi) { }

  getAllPolicies(): Observable<AdminPolicy[]> {
    return this.api.getAllPolicies().pipe(
      map(policies => this.mapAdminPolicies(policies))
    );
  }
  getPolicyById(id: string): Observable<AdminPolicy> {
    return this.api.getPolicyById(id).pipe(
      map(policy => this.mapFromDto(policy))
    );
  }
  postPolicy(policy: AdminPolicy): Observable<AdminPolicy> {
    return this.api.postPolicy(this.mapToDto(policy)).pipe(
      map(res => this.mapFromDto(res))
    );
  }
  putPolicy(id: string, policy: AdminPolicy): Observable<object> {
    return this.api.putPolicy(id, this.mapToDto(policy));
  }
  deletePolicy(id: string): Observable<object> {
    return this.api.deletePolicy(id);
  }
  getPoliciesByUser(): Observable<AdminPolicy[]> {
    return this.api.getPoliciesByUser().pipe(
      map(policies => this.mapAdminPolicies(policies))
    );
  }

  mapAdminPolicies(item: AdminPolicyDto[]): AdminPolicy[] {
    if (item instanceof Array) {
      const items = item.map(dto => this.mapFromDto(dto));
      return items;
    }
  }

  mapFromDto(dto: AdminPolicyDto): AdminPolicy {
    if (!dto) {
      return ({
        appId: undefined,
        name: undefined,
        editors: []
      });
    }
    return ({
      appId: dto.appId,
      name: dto.appName,
      editors: dto.editors
    });
  }

  mapToDto(policy: AdminPolicy): AdminPolicyDto {
    return ({
      appId: policy.appId,
      appName: policy.name,
      editors: policy.editors
    });
  }
}
