import { CanEditGuard } from './../can-edit.guard';
import { AdminPolicyManagementComponent } from './../admin-policy-management/admin-policy-management.component';
import { PermissionsFormComponent } from './../permissions-form/permissions-form.component';
import { CanDeactivateRoleGuard } from './../can-deactivate-role.guard';
import { RolesFormComponent } from './../roles-form/roles-form.component';
import { PolicyDetailComponent } from './../policy-detail/policy-detail.component';
import { HomeComponent } from './../home/home.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CanDeactivatePermissionGuard } from '../can-deactivate-permission.guard';


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: HomeComponent
        },
        {
          path: 'policy/:id',
          component: PolicyDetailComponent
        },
        {
          path: 'policy/:id/role',
          component: RolesFormComponent,
          canDeactivate: [CanDeactivateRoleGuard]
        },
        {
          path: 'policy/:id/permission',
          component: PermissionsFormComponent,
          canDeactivate: [CanDeactivatePermissionGuard]
        },
        {
          path: 'manage/:id',
          component: AdminPolicyManagementComponent,
          canActivate: [CanEditGuard]
        },
        {
          path: '**',
          component: HomeComponent
        }
      ]
    )
  ]
})
export class AppRoutingModule { }
