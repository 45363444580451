import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Permission } from './models/policy-service.models';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  currentPermission: BehaviorSubject<Permission> = new BehaviorSubject(null);
  constructor() { }

  setPermission(permission: Permission) {
    this.currentPermission.next(permission);
  }

  setNewPermission() {
    this.currentPermission.next(null);
  }

}
