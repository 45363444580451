import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'signet-delete-confirm-dialog',
  templateUrl: './delete-confirm-dialog.component.html',
  styleUrls: ['./delete-confirm-dialog.component.scss']
})
export class DeleteConfirmDialogComponent implements OnInit {
  @Input() resourceType: string;
  @Output() confirmed: EventEmitter<void> = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<DeleteConfirmDialogComponent>
  ) { }

  ngOnInit() {
  }

  onConfirmClicked() {
    this.confirmed.emit();
    this.dialogRef.close();
  }

  onCancelClicked() {
    this.dialogRef.close();
  }

}
