import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PolicyService } from './../policy.service';
import { PermissionService } from './../permission.service';
import { Component, OnInit, Input } from '@angular/core';
import { Permission } from '../models/policy-service.models';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'signet-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
  @Input() permissions: Permission[];
  displayedColumns: string[] = ['permission', 'roles', 'canEdit'];
  dialogConfig: MatDialogConfig = { width: '800px' };
  isReadOnly: Observable<boolean>;

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private policyService: PolicyService
  ) { }

  ngOnInit() {
    this.isReadOnly = this.policyService.isReadOnly;
  }

  onAddPermissionClicked() {
    this.permissionService.setNewPermission();
    this.navigateToPermissionComponent();
  }

  onEditPermissionClicked(permission: Permission) {
    this.permissionService.setPermission(permission);
    this.navigateToPermissionComponent();
  }

  navigateToPermissionComponent() {
    this.router.navigate(['policy', this.policyService.applicationPolicyId.value, 'permission']);
  }
}
