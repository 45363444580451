import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from "./environments/environment";

export const baseApiUrl = environment.baseUrl + '/api/v1/';
export const baseIssuerUrl = environment.baseIdentityAuthorityUrl;
export const authConfig: AuthConfig = {
  issuer: baseIssuerUrl,
  redirectUri: window.location.origin + '/index.html',
  clientId: 'PolicyServiceAdminAPI',
  responseType: 'id_token token',
  scope: 'openid profile roles PolicyServiceAdminAPI',
  userinfoEndpoint: `${baseIssuerUrl}/connect/userinfo`
};

export const AdminPolicyId = "83fe0c13-327c-430a-a67f-65f941e9badc";