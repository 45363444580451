import { baseApiUrl } from '../variables';
import { ApplicationPolicy } from './models/policy-service.models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PolicyApi {

    constructor(
        private httpClient: HttpClient
    ) { }

    getApplicationPolicies(): Observable<ApplicationPolicy[]> {
        return this.httpClient.get<ApplicationPolicy[]>(`${baseApiUrl}ApplicationPolicy`);
    }

    getApplicationPolicy(id: string): Observable<ApplicationPolicy> {
        return this.httpClient.get<ApplicationPolicy>(`${baseApiUrl}ApplicationPolicy/${id}`);
    }

    postApplicationPolicy(policy: ApplicationPolicy): Observable<ApplicationPolicy> {
        return this.httpClient.post<ApplicationPolicy>(`${baseApiUrl}ApplicationPolicy`, policy);
    }

    deleteApplicationPolicy(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${baseApiUrl}ApplicationPolicy/${id}`);
    }

    putApplicationPolicy(id: string, policy: ApplicationPolicy): Observable<ApplicationPolicy> {
        return this.httpClient.put<ApplicationPolicy>(`${baseApiUrl}ApplicationPolicy/${id}`, policy);
    }
}
