import { PolicyService } from './../policy.service';
import { RolesService } from './../roles.service';
import { Role } from './../models/policy-service.models';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'signet-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  @Input() roles: Role[];
  displayedColumns: string[] = ['role', 'identityRoles', 'subjects', 'canEdit'];
  isReadOnly: Observable<boolean>;
  constructor(
    private rolesService: RolesService,
    private router: Router,
    private policyService: PolicyService
  ) { }

  ngOnInit() {
    this.isReadOnly = this.policyService.isReadOnly;
  }

  onEditRoleClicked(role: Role) {
    this.rolesService.setRole(role);
    this.navigateToRoleComponent();
  }

  onAddRoleClicked() {
    this.rolesService.setNewRole();
    this.navigateToRoleComponent();
  }

  navigateToRoleComponent() {
    this.router.navigate(['policy', this.policyService.applicationPolicyId.value, 'role']);
  }

}
