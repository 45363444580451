import { Injectable } from '@angular/core';
import { Role } from './models/policy-service.models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  currentRole: BehaviorSubject<Role> = new BehaviorSubject<Role>(null);

  constructor() {
  }

  setRole(role: Role) {
    this.currentRole.next(role);
  }

  setNewRole() {
    this.currentRole.next(null);
  }
}
