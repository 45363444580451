import { baseApiUrl } from './../variables';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminPolicyDto } from './models/admin.models';

@Injectable({
  providedIn: 'root'
})
export class AdminApi {

  constructor(private http: HttpClient) { }

  getAllPolicies(): Observable<AdminPolicyDto[]> {
    return this.http.get<AdminPolicyDto[]>(`${baseApiUrl}Admin`);
  }
  getPolicyById(id: string): Observable<AdminPolicyDto> {
    return this.http.get<AdminPolicyDto>(`${baseApiUrl}Admin/${id}`);
  }
  postPolicy(policy: AdminPolicyDto): Observable<AdminPolicyDto> {
    return this.http.post<AdminPolicyDto>(`${baseApiUrl}Admin`, policy);
  }
  putPolicy(id: string, policy: AdminPolicyDto): Observable<object> {
    return this.http.put<null>(`${baseApiUrl}Admin/${id}`, policy);
  }
  deletePolicy(id: string): Observable<object> {
    return this.http.delete(`${baseApiUrl}Admin/${id}`);
  }
  getPoliciesByUser(): Observable<AdminPolicyDto[]> {
    return this.http.get<AdminPolicyDto[]>(`${baseApiUrl}Admin/UserPolicies`);
  }
}
