import { MatDialog } from '@angular/material/dialog';
import { RolesService } from './../roles.service';
import {
  Component, OnInit, OnDestroy, EventEmitter,
  HostListener, ViewChildren, ElementRef, QueryList, Output
} from '@angular/core';
import { Role, ApplicationPolicy } from '../models/policy-service.models';
import { Observable, combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { FormGroup, FormControl, FormArray, AbstractControl, Validators } from '@angular/forms';
import { SelectListItem } from '../models/form-field.models';
import { PolicyService } from '../policy.service';
import { tap, map, switchMap, takeUntil, withLatestFrom, take } from 'rxjs/operators';
import { mapStringToSelectListItem } from '../shared/utils/select-list.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DeleteConfirmDialogComponent } from '../delete-confirm-dialog/delete-confirm-dialog.component';

@Component({
  selector: 'signet-roles-form',
  templateUrl: './roles-form.component.html',
  styleUrls: ['./roles-form.component.scss']
})
export class RolesFormComponent implements OnInit, OnDestroy {
  @Output() save: EventEmitter<string> = new EventEmitter();
  @ViewChildren('identityRoles', { read: ElementRef }) identityRoleElements: QueryList<ElementRef>;
  @ViewChildren('subjects', { read: ElementRef }) subjectElements: QueryList<ElementRef>;

  role: Observable<Role>;
  appPolicy: Observable<ApplicationPolicy>;
  rolesForm: FormGroup = new FormGroup({
    roleName: new FormControl('', { updateOn: 'blur', validators: Validators.required }),
    identityRoles: new FormArray([]),
    subjects: new FormArray([])
  });
  subjectSelectListOptions: SelectListItem[];
  ngUnsubscribe: Subject<void> = new Subject();
  isReadOnly: Observable<boolean>;
  isDeleting: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canDelete: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private roleStream: BehaviorSubject<Role> = new BehaviorSubject<Role>(null);
  private saveStream: Subject<void> = new Subject();
  constructor(
    private policyService: PolicyService,
    private rolesService: RolesService,
    private snackbar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    combineLatest(
      this.rolesForm.controls.roleName.valueChanges,
      this.rolesForm.controls.identityRoles.valueChanges,
      this.rolesForm.controls.subjects.valueChanges
    ).pipe(
      map((aggregate: [string, string[], string[]]) =>
        ({
          name: aggregate[0],
          identityRoles: aggregate[1].filter(item => !!item),
          subjects: aggregate[2].filter(item => !!item)
        })),
      tap(role => this.roleStream.next(role)),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();

    this.isReadOnly = this.policyService.isReadOnly.pipe(
      tap(isReadOnly => isReadOnly ? this.rolesForm.disable() : this.rolesForm.enable())
    );

    this.role = this.rolesService.currentRole.pipe(
      tap(role => {
        if (!!role) {
          this.canDelete.next(true);
          this.rolesForm.controls.roleName.setValue(role.name);
          const identityRoleArr = this.rolesForm.controls.identityRoles as FormArray;
          const subjectsArr = this.rolesForm.controls.subjects as FormArray;

          if (role.identityRoles.length > 0) {
            role.identityRoles.forEach(identityRole => identityRoleArr.push(new FormControl(identityRole)));
          } else {
            identityRoleArr.push(new FormControl());
          }

          if (role.subjects.length > 0) {
            role.subjects.forEach(subject => {
              const control = new FormControl();
              control.setValue(subject);
              subjectsArr.push(control);
            });
          } else {
            subjectsArr.push(new FormControl());
          }
        } else {
          this.canDelete.next(false);
          const identityRoleArr = this.rolesForm.controls.identityRoles as FormArray;
          const subjectsArr = this.rolesForm.controls.subjects as FormArray;
          identityRoleArr.push(new FormControl());
          subjectsArr.push(new FormControl());
        }
      }),
      takeUntil(this.ngUnsubscribe)
    );

    // We don't want to subscribe in the template since this value may be null for a new role
    this.role.subscribe();

    this.appPolicy = this.policyService.applicationPolicy.pipe(
      tap(appPolicy => {
        if (!!appPolicy) {
          const roles = appPolicy.policy.roles.map(role => role.name);
          this.subjectSelectListOptions = this.mapSubjectsToSelectListItems(roles);
        }
      }),
      map(policy => {
        if (!!policy) {
          return policy;
        } else {
          return this.policyService.generateApplicationPolicy();
        }
      })
    );

    this.saveStream.pipe(
      withLatestFrom(this.appPolicy),
      map((stream: [void, ApplicationPolicy]) => stream[1]),
      withLatestFrom(this.roleStream),
      map((aggregate: [ApplicationPolicy, Role]) =>
        ({
          appPolicy: aggregate[0],
          role: {
            name: aggregate[1].name,
            identityRoles: aggregate[1].identityRoles.filter(item => !!item),
            subjects: aggregate[1].subjects.filter(item => !!item)
          }
        })),
      map(policyWithRoles => {
        const appPolicy = policyWithRoles.appPolicy;

        let roleIndex = -1;
        if (!!this.rolesService.currentRole.value) {
          roleIndex = appPolicy.policy.roles.findIndex(policyRole => policyRole.name === this.rolesService.currentRole.value.name);
        }

        if (roleIndex > -1) {
          if (!this.isDeleting.value) {
            appPolicy.policy.roles[roleIndex] = policyWithRoles.role;
          } else {
            // Delete the element
            const role = appPolicy.policy.roles[roleIndex];
            appPolicy.policy.permissions.forEach(permission => {
              var index = permission.roles.findIndex(permissionRole => permissionRole === role.name);
              if (index > -1) {
                permission.roles.splice(index, 1);
              }
            });
            appPolicy.policy.roles.splice(roleIndex, 1);
          }

        } else {
          appPolicy.policy.roles.push(policyWithRoles.role);
        }

        return appPolicy;
      }),
      switchMap(policy => {
        if (this.rolesForm.valid) {
          if (!!policy.id) {
            return this.policyService.updateApplicationPolicy(policy.id, policy);
          } else {
            return this.policyService.insertApplicationPolicy(policy).pipe(
              tap(savedPolicy => {
                this.policyService.applicationPolicyId.next(savedPolicy.id);
              })
            );
          }
        }
      }),
      tap(p => this.snackbar.open(`Successfully saved role: ${this.roleStream.value.name}`, 'Dismiss', { duration: 1500 })),
      tap(p => this.save.emit(this.roleStream.value.name)),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(pol => {
      // Mark the form as pristine to avoid unnecessary unload confirmation
      this.rolesForm.markAsPristine();

      // On an update we do not receive any content for the policy, so use the policy we are already
      // tracking to determine navigation
      this.appPolicy.pipe(
        tap(p => this.policyService.refreshStream.next()),
        take(1)
      ).subscribe(policy => this.router.navigate(['/', 'policy', policy.id]));
    });
  }

  mapIdentityRolesToSelectListItems(arr: string[]): SelectListItem[] {
    const opts = mapStringToSelectListItem(arr);
    return opts.map(item => {
      this.role.pipe(
        map(role => role.identityRoles.find(identityRole => identityRole === item.value)),
        tap(role => {
          if (!!role) {
            item.isSelected = true;
          }
        })
      );

      return item;
    });
  }

  mapSubjectsToSelectListItems(arr: string[]): SelectListItem[] {
    const opts = mapStringToSelectListItem(arr);
    return opts.map(item => {
      this.role.pipe(
        map(role => role.subjects.find(subject => subject === item.value)),
        tap(role => {
          if (!!role) {
            item.isSelected = true;
          }
        })
      );

      return item;
    });
  }

  onAddIdentityRoleClicked() {
    const formArray = this.rolesForm.controls.identityRoles as FormArray;
    formArray.push(new FormControl(''));
    this.focusLastElement(this.identityRoleElements);
  }

  onAddSubjectClicked() {
    const formArray = this.rolesForm.controls.subjects as FormArray;
    formArray.push(new FormControl(''));
    this.focusLastElement(this.subjectElements);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event: BeforeUnloadEvent) {
    $event.stopPropagation();
    return this.handleUnload($event);
  }

  @HostListener('window:unload', ['$event'])
  onUnload($event: BeforeUnloadEvent) {
    $event.stopPropagation();
    this.handleUnload($event);
  }

  handleUnload($event: BeforeUnloadEvent): BeforeUnloadEvent {
    if (!this.rolesForm.pristine) {
      if (confirm('Are you sure you wish to leave this page without saving your changes?')) {
        $event.returnValue = 'Your changes will not be saved';
      } else {
        $event.returnValue = 'Continue';
      }
    }

    return $event;
  }

  focusLastElement(elements: QueryList<ElementRef>) {
    // this setTimeout is necessary due to Angular change detection needing to run before the new form control
    // is created. Without this, the previous element will be focused instead of the one we just added
    setTimeout(() => elements.last.nativeElement.focus());
  }

  onClearButtonClick(control: AbstractControl) {
    control.setValue('');
  }

  onSaveClicked() {
    this.saveStream.next();
  }

  onDeleteClicked() {
    const deleteDialog = this.dialog.open(DeleteConfirmDialogComponent);
    deleteDialog.componentInstance.confirmed.pipe(
      take(1)
    ).subscribe(
      success => {
        this.isDeleting.next(true);
        this.saveStream.next();
      }
    );
  }

  getNestedFormArray(formGroupName) {
    const form = this.rolesForm.get(formGroupName);
    return form as FormArray;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
