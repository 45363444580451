import { User } from './models/user.models';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService implements OnDestroy {
  user: BehaviorSubject<User> = new BehaviorSubject(null);
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false)
  userRole: BehaviorSubject<'Admin' | 'User'> = new BehaviorSubject('User');
  private ngUnsubscribe: Subject<void> = new Subject();

  constructor(private oauthService: OAuthService) {

  }

  configureAuth() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  }

  initialLogin() {
    this.oauthService.loadDiscoveryDocumentAndLogin().then(p => {
      this.isLoading.next(true);
      this.oauthService.loadUserProfile().then((userInfo: User) => {
        this.user.next(userInfo);
        this.isLoading.next(false);
      });
    });
  }

  loginUser() {
    this.oauthService.initLoginFlow();
  }

  logoutUser() {
    this.oauthService.logOut();
  }

  setUser(): any {
    this.oauthService.loadUserProfile().then((p: User) => this.user.next(p));
  }

  hasScope(permission: string): boolean {
    return !!this.oauthService.getGrantedScopes()[permission];
  }

  getIdToken(): string {
    // Gets the token that is to be sent in the authorization header for our API requests
    return this.oauthService.getIdToken();
  }

  getAccessToken(): string {
    // Access token that allows us to make requests to the Identity server endpoints
    return this.oauthService.getAccessToken();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
