import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { MaterialModule } from './shared/material/material.module';
import { NavbarComponent } from './navbar/navbar.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { HomeComponent } from './home/home.component';
import { PoliciesComponent } from './policies/policies.component';
import { PolicyDetailComponent } from './policy-detail/policy-detail.component';
import { PolicyBackButtonComponent } from './policy-back-button/policy-back-button.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { RolesComponent } from './roles/roles.component';
import { StringArrayCapitalizePipe } from './string-array-capitalize.pipe';
import { PermissionsFormComponent } from './permissions-form/permissions-form.component';
import { AutocompleteMultiselectComponent } from './autocomplete-multiselect/autocomplete-multiselect.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SignetHttpInterceptor } from './signet.interceptor';
import { RolesFormComponent } from './roles-form/roles-form.component';
import { PolicyDetailBackBtnComponent } from './policy-detail-back-btn/policy-detail-back-btn.component';
import { PolicyMetadataComponent } from './policy-metadata/policy-metadata.component';
import { DeleteConfirmDialogComponent } from './delete-confirm-dialog/delete-confirm-dialog.component';
import { oAuthStorageFactory } from './shared/utils/oauth-storage.utils';
import { AdminPolicyManagementComponent } from './admin-policy-management/admin-policy-management.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    PoliciesComponent,
    PolicyDetailComponent,
    PolicyBackButtonComponent,
    PermissionsComponent,
    RolesComponent,
    StringArrayCapitalizePipe,
    PermissionsFormComponent,
    AutocompleteMultiselectComponent,
    RolesFormComponent,
    PolicyDetailBackBtnComponent,
    PolicyMetadataComponent,
    DeleteConfirmDialogComponent,
    AdminPolicyManagementComponent
  ],
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    RouterModule,
    AppRoutingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SignetHttpInterceptor, multi: true },
    { provide: OAuthStorage, useFactory: oAuthStorageFactory }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DeleteConfirmDialogComponent
  ]
})
export class AppModule { }
